<template>
  <div class="bgcard">
    <div class="serverfroms">
      <el-table :data="tableData.content" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column :label="$t('lang.fileName')" align="center">
					<template #default="scope">
					  <span v-if="scope.row.devType == 1">UV100</span>
					  <span v-if="scope.row.devType == 2">UV800</span>
					</template>
        </el-table-column>
        <el-table-column :label="$t('lang.fileSize')" align="center">
					<template #default="scope">
					  <span>{{scope.row.mid |mids}}</span>
					</template>
        </el-table-column>
        <el-table-column prop="version" :label="$t('lang.Generationtime')" align="center">
        </el-table-column>
        <el-table-column :label="$t('lang.Modelofequipmentused')" align="center">
					<template #default="scope">
					  <span>{{scope.row.versionTm |time}}</span>
					</template>
        </el-table-column>
        <el-table-column prop="createDate" :label="$t('lang.Forceupgrade')" align="center">
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center"  v-if="tableData.content">
        <div class="allNum">{{ $t("lang.total") }}: {{ tableData.totalElements }}</div>
        <el-pagination popper-class="paging" background layout="prev, pager, next" :total="tableData.totalElements" 
					@current-change="handlePageCurrentChange" :prev-text="$t('lang.previouspage')" :next-text="$t('lang.nextpage')">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as pment from "@/api/equipment.js";
import { getTime } from "@/utils/time";
export default {
  data() {
    return {
      tableData: [],
      parsm: {
        page: 1,
        size: 10,
      },
    };
  },
  mounted() {
    this.getList();
  },
	filters:{
		time(val){
			return getTime(val*1000).time
		},
		mids(val){
			let sk=''
			if(val){
				sk=val.slice(16)
			}
			return sk
		}
	},
  methods: {
    //获取升级文件列表
    async getList() {
      let res = await pment.filelist(this.parsm);
      console.log(res);
			this.tableData = res.data;
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.headerMsg {
  display: flex;
  justify-content: space-between;

  .addbtn {
    button {
      height: 0.5rem;
      border: none;
      outline-style: none;
      padding: 0px 0.12rem;
      background: #307b37;
      border-radius: 0.1rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
.upgrade {
  width: 3.5rem;
  margin-bottom: 0.2rem;
  display: flex;

  label {
    width: 1rem;
    height: 0.4rem;
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 0.14rem;
    color: #606266;
    line-height: 0.4rem;
    padding: 0 0.12rem 0 0;
    box-sizing: border-box;
  }

  div {
    width: 2rem;

    .el-button {
      margin-top: 0.05rem;
      background-color: #1061c5;
      color: #fff;
      border: none;
    }
  }
}
.buttons {
  padding: 0px;
  width: 0.82rem;
  height: 0.42rem;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background-color: #24b4de;
}
.binding {
  margin-bottom: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  label {
    width: 1rem;
  }
  .el-input,
  .el-select {
    width: 2rem;
  }
}
.button_a {
  border: none;
  outline-style: none;
  width: 0.92rem;
  height: 0.46rem;
  background: #ededed;
  border: 0.01rem solid #438749;
  border-radius: 0.1rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #438749;
    line-height: 0.35rem;
    text-align: center;
  }
}

.button_a:nth-child(2) {
  width: 0.92rem;
  height: 0.46rem;
  background: #438749;
  border-radius: 0.1rem;
  margin-left: 0.2rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.35rem;
  }
}
</style>
