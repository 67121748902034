import request from '../utils/request.js'



//获取设备列表
export function list(data) {
	return request({
		url: `/device/list`,
		method: 'get',
		params:data
	})
}
//添加设备
export function add(data) {
	return request({
		url: `/device/add`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//获取设备型号列表
export function model() {
	return request({
		url: `/device/model`,
		method: 'get'
	})
}
//获取设备详情信息
export function detai(data) {
	return request({
		url: `/device/query/deviceDetails`,
		method: 'get',
		params:data
	})
}
//查询设备配置参数
export function parameter(data) {
return request({
	url: `/device/query/deviceParameter`,
	method: 'get',
	params:data
})
}
//编辑设备
export function edit(data) {
	return request({
		url: `/device/Parameter`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//捆绑设备
export function binding(data) {
	return request({
		url: `/device/add/device`,
		method: 'post',
		data,
	})
}
//解除绑定
export function unbinding(data) {
	return request({
		url: `/device/delete`,
		method: 'put',
		params:data
	})
}
//删除设备
export function deletes(data) {
	return request({
		url: `/device/admin/delete`,
		method: 'DELETE',
		params:data
	})
}
//添加文件信息
export function addfile(data) {
	return request({
		url: `/device/upload`,
		method: 'POST',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}
//获取设备上报记录列表
export function filelist(data) {
	return request({
		url: `device/deviceUpgradeLog`,
		method: 'get',
		params:data
	})
}
//获取设备升级管理列表
export function filelists() {
	return request({
		url: `device/upgrade/list`,
		method: 'get',
	})
}
//获取设备日志列表
export function journallist(data) {
	return request({
		url: `/device/deviceLog/list`,
		method: 'get',
		params:data
	})
}
//删除设备升级日志
export function deletefile(data) {
	return request({
		url: `device/deleteFile?id=${data.id}`,
		method: 'delete',
	})
}
//获取设备日志详情
export function journaldetails(data) {
	return request({
		url: `/device/deviceLog/details`,
		method: 'get',
		params:data
	})
}

/********************************/
//设备设置
export function setDevice(data) {
	return request({
		url: `/device/Parameter`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}

//导入方案
export function importPlan(data) {
	return request({
		url: `/solution/addDeviceSite`,
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/json'
		}
	})
}

//删除当前设备的推流地址
export function delCurUrl(data) {
	return request({
		url: `/device/deleteDeviceStream`,
		method: 'get',
		params:data
	})
}